body {
  background: #2e1840;
}
StrictMode {
  background: #2e1840;
}
a {
  text-decoration: none !important;
  color: white !important;
  transition: ease-in-out;
}
a:link {
  text-decoration: none !important;
  color: white !important;
}
a:hover {
  color: #f17b89 !important;
}

.App {
  font-family: sans-serif;
  background-color: #2e1840;
}
main {
  background-image: url("https://s31.picofile.com/file/8469789518/dictionary_background1.jpg");
  background-repeat: no-repeat;
  width: 100%;
}
.hi {
  height: 500px;
}
.header {
  background: #33263d;
  padding: 20px;
}
.title {
  text-align: start;
  font-size: 22px !important;
  color: white !important;
}
.icon {
  padding: 0;
  padding-left: 10px;
  padding-right: 8px;
  padding-bottom: 5px;
}
.today-word {
  text-align: center;
  padding-top: 70px;
  margin: 0px 200px;
  margin-bottom: 40px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.today-word-heading {
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  display: flex;
  justify-content: space-between;
  background: rgba(78, 63, 90, 0.8);
  padding: 10px 30px;
}
.today-word-body {
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(10, 1, 12, 0.9);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.Search-box {
  margin: 0px 200px;
  border-radius: 5px;
  background: rgba(78, 63, 90, 0.8);
  padding-left: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin-bottom: 30px;
}
.input-all {
  width: 100%;
}
.Search-input {
  margin: 10px 10px;
  background-color: rgb(10, 1, 12);
  color: white;
  border-radius: 5px;
  border: none;
  max-width: 90%;
  width: 80%;
  height: 40px;
}
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
  color: white;

  position: relative;
  top: 5px;
}
.headers {
  font-size: 23px;
  color: coral;
  padding-top: 10px;
}
.result-surronding {
  border-top: grey 1.5px solid;
  color: white;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 5px;
}
.result-left {
  background: rgba(10, 1, 12, 0.7);
}

.result-right {
  background: rgba(46, 24, 64, 0.7);
  padding: 30px;
}

.result-heading {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: #f17b89 1.5px solid;
}
.fonetic {
  padding: 5px;
}
.word {
  font-size: 20px;
}
.list {
  padding: 20px 10px;
  font-size: 12px;
  list-style: none;
}
.list-all {
  position: fixed;

  bottom: 7%;
}
.result-body {
  margin-top: 20px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.part-of-speach {
  width: 80px;
  font-size: 12px;
  background: rgb(50, 34, 139);
  text-align: center;
  border: rgb(246, 246, 247) 1px solid;
  border-radius: 10px;
  padding: 1px 0px;
}
.syn-icon {
  color: rgb(16, 138, 16);
}
.ant-icon {
  color: tomato;
}
.imgs {
  width: 100px;
  height: 100px;
  text-align: center;
  display: block;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all 200ms ease-in-out;
}

.imgs:hover {
  opacity: 0.5;
  transform: scale(1.2);
}
footer {
  color: white;
  padding: 20px 0px;
  text-align: center;
  background-color: #33263d;
}

@media (max-width: 868px) {
  .today-word {
    margin: 30px 50px;
  }
  .extra-icon {
    display: none;
  }
  .Search-box {
    margin: 0 50px;
  }
  .today-word-heading {
    padding: 10px 10px;
  }
  .result-left {
    display: none;
  }
  .result-surronding {
    display: block;
    margin-top: 30px;
  }
  main {
    background-color: rgba(0, 0, 0, 0.25);
  }
}
